import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import FurtherReading from "../components/furtherReading"

const BadIdeasGood = () => (
  <Layout>
    <SEO
      title="Bad Ideas Become Good Ideas"
      description="You are searching for something that will change the world for the better, make you rich or both at the same time. You need a great idea then you need to execute on it"
    />
    <h1>Bad Ideas Become Good Ideas</h1>
    <p>You are searching for something that will change the world for the better, make you rich or both at the same time. You need a great idea then you need to execute on it. Execute really well and move fast enough so any competition you have never catches up or even compares.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/hScxHOv.jpg"
        alt="Bad Ideas Become Good Ideas"
        description="sapling growing from the dirt"
      />
    </div>
    <p>
      Photo by{" "} 
      <a
        href="https://unsplash.com/@pgyamtsho"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      Pema Gyamtsho
      </a>
      {" "} on Unsplash
    </p>

    <p>It’s easier said than done because although most people feel they have a wonderful idea that will be of great use to all that set eyes on it, the reality is a bit different. If you have something you think is special, go and share it with an expert and they will give you many reasons why your idea is crap and that’s if it doesn’t exist in some form already.</p>
    <p>Don’t be disheartened. It’s the process of wading through bad ideas while gaining knowledge that allows you to come up with great ideas. Let me say that again in other words. Knowledge and experience breeds good ideas.</p>
    <p>In order to put this into practice and get to your good ideas, I have some guidance on what you can do. Write down every idea that comes to you using a business plan outline to help you get everything down that you need to.</p>
    <p>What your product is, who it serves, how much it will cost to set up, estimated profits in the first 3 years, how much time you will need to put in etc along with information about competitors and what makes you special.</p>
    <p>During this process you need to be host with yourself and point out any real problems that could cause the business to fail, either now or further down the line.</p>
    <p>When you’ve got everything laid out, it’s time to start getting in touch with potential customers. You may ask; “this early, before I have even built anything?”. The answer is yes, this early. It’s important to do this as soon as possible because you will be testing out the idea properly.</p>
    <p>By presenting your idea as if it is a real business that already exists, you will get real feedback. If people don’t agree to work with you then you adjust your pitch until you get some yeses. It could be the idea or it could be your sales skills, you won’t know until you contact many people.</p>
    <p>If they say yes and agree to work with you then you know you have something. If they do say yes then explain the situation and take their contact details for future use.</p>
    <p>Either way, you should use this time as. learning process. You should be able to go back to the original business plan you wrote and adjust it accordingly. In the worst case, you have to write the idea off and come up with another one. The new idea might even come from one of the conversations you had with the list of people you have been in contact with.</p>
    <p>The idea here is to implement fast, fail fast and learn fast.</p>

    <h2>Looking Back, Realisation</h2>
    <p>There will come a point where you are in the habit of getting your ideas down, vetting them yourself then testing them with potential customers. You will hopefully be able to spot a pattern in the type of ideas you come up with. Are they safe and small developments on existing ideas? Or are they potential world changers? That’s up to you to decide, hopefully you’ve been writing that down too.</p>
    <p>Eventually, you will have learned a lot about business plans. Now it’s time to look back at your earlier ideas and review them as if you are someone else looking in. That idea you thought was amazing 6 months ago might seem terrible now. Look at your positives and negatives and see if you were being blinded because it was your own idea or you truly were being host with yourself.</p>
    <p>I don’t know how long this process should take. Keep going until you see real development in your ideas. If you have great knowledge in a certain domain combined with the drive to create something then you are already primed for success. The thing is that knowledge in an area comes with time and experience.</p>
    <p>There are a few great minds who create great things at a young age but don’t compare yourself to these people. They were lucky enough to find their passion early through guidance, community or anything else that can help. They also put a lot of time in, they just started earlier than you.</p>


    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default BadIdeasGood
